<template>
    <!-- site content -->
    <div class="main-page pt-3">
        <main class="main-container">
            <div class="container custom-container">
                <section>
                    <div class="row">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                            <div class="table-box p-3">
                                <div class="border-bottom pb-3">
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <router-link :to="'/projects/create-project'" class="btn btn-primary"
                                                         type="button" v-if="data.permission.canCreate">
                                                Create New
                                            </router-link>
                                        </div>
                                        <div class="expand-ctl">
                                            <p>
                                                <button class="btn btn-primary" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseProject-ctl" aria-expanded="false"
                                                        aria-controls="collapseProject-ctl">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         fill="currentColor" class="bi bi-funnel-fill" viewBox="0 0 16 16">
                                                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                                                    </svg>
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="collapse mt-3" id="collapseProject-ctl">
                                        <div class="row">
                                            <div class="col-xxl-4 col-xl4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <select class="form-select" aria-label="Project-status"
                                                        v-model="setParams.status">
                                                    <option :value="'null'">All</option>
                                                    <option :value="'Active'">Active</option>
                                                    <option :value="'Inactive'">Inactive</option>
                                                    <option :value="'Completed'">Completed</option>
                                                    <option :value="'Canceled'">Canceled</option>
                                                </select>
                                            </div>
                                            <div class="col-xxl-4 col-xl4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <select class="form-select" aria-label="Project-status"
                                                        v-model="setParams.type">
                                                    <option :value="'null'">All</option>
                                                    <option :value="'new'">New</option>
                                                    <option :value="'existing'">Existing</option>
                                                </select>
                                            </div>
                                            <div class="col-xxl-4 col-xl4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div class="input-group search-box">
                                                    <input type="text" class="form-control" placeholder="Search"
                                                           name="search" v-model="setParams.search">
                                                    <button class="btn btn-outline-secondary" type="button"
                                                            @click="searchList">
                                                        <font-awesome-icon :icon="['fas', `search`]"></font-awesome-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <data-table id="example-data-table"
                                    :options="options"
                                    :data-set="data.dataList"
                                    :page-size="data.pageSize"
                                    @change-action="changeAction"
                                    @change-status="changeStatus"
                                    @pagination="pagination"
                                    @sorting="sorting"
                                    @limit-change="limitChange"
                                />

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>

        <bottom-footer></bottom-footer>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="addMilestoneModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Project</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <Form ref="resetForm" @submit="formAction({url: data.setURL, modalId: 'showModal'}, data.inputFormData)"
                      v-slot="{ errors }" :validation-schema="schema">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label class="form-control-label">Project Name</label>
                                <Field type="text" class="form-control" v-model="data.inputFormData.project_name"
                                       :class="{'border-danger': errors.project_name}" name="project_name"/>
                                <span class="text-danger" v-if="errors.project_name">{{ errors.project_name }}</span>
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-control-label">Serial</label>
                                <Field type="text" class="form-control" v-model="data.inputFormData.serial"
                                       :class="{'border-danger': errors.serial}" name="serial"/>
                                <span class="text-danger" v-if="errors.serial">{{ errors.serial }}</span>
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-control-label"></label>
                                <span class="me-3">
                                    <input type="radio" id="productType" name="product-Type"
                                           v-model="data.inputFormData.type" value="new" checked>
                                    <label for="productType" class="ms-2">New Development</label>
                                </span>
                                <span>
                                    <input type="radio" id="productType2" name="product-Type"
                                           v-model="data.inputFormData.type"
                                           value="existing">
                                    <label for="productType2" class="ms-2">Product Update </label>
                                </span>
                            </div>
                            <div class="col-12 mb-3" v-if="data.inputFormData.type == 'existing'">
                                <select class="form-control" name="project_id" v-model="data.inputFormData.project_id">
                                    <option :value="''">Select Project</option>
                                    <option v-for="(list, index) in completedProjects()" :key="index" :value="list.id">
                                        {{list.project_name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-control-label">Project Start Date</label>
                                <div class="form-group">
                                    <Field type="date" class="form-control" name="start_date"
                                           :class="{'border-danger': errors.start_date}"
                                           v-model="data.inputFormData.start_date"/>
                                    <span class="text-danger" v-if="errors.start_date">{{ errors.start_date }}</span>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-control-label">Status</label>
                                <div class="form-group">
                                    <Field as="select" class="form-control" name="status"
                                           :class="{'border-danger': errors.status}"
                                           v-model="data.inputFormData.status">
                                        <option :value="''">Select Status</option>
                                        <option v-for="(list, index) in data.projectStatus" :key="index" :value="list">
                                            {{list}}
                                        </option>
                                    </Field>
                                    <span class="text-danger" v-if="errors.status">{{ errors.status }}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <!-- /Modal -->

</template>

<script>
    import HelperFunction from "@/common/helpers";
    import DataTable from "@/common/components/datatable";
    import {useStore} from "vuex";
    import {useRoute} from "vue-router";
    import {watch, onMounted} from "vue";
    import * as Yup from "yup";
    import {Field, Form} from "vee-validate";
    import BottomFooter from "@/views/layouts/auth/common/BottomFooter";

    export default {
        name: "Project",
        components: {
            DataTable, Field, Form, BottomFooter
        },
        setup(props) {
            const {data, openModal, editFormData, deleteListData, getAllData, storeFormData, resetForm, permissionResolver} = HelperFunction();
            const store = useStore();
            const route = useRoute();
            const company = store.getters.getSelectedBoard;
            let setParams = {
                company_id: company.id,
                status: null,
                type: null
            };
            data.projectStatus = ["Active", "Inactive", "Completed", "Canceled"]

            data.setURL = vueConfig.Project.ProjectEndPoint
            onMounted(async () => {
                getAllData(data.setURL, setParams);
            })

            /** watch **/
            permissionResolver(route.path);
            
            watch(() => data.dataList.data, (value) => {
                if (value !== undefined) {
                    value.map((item) => {
                        if(item.status == "Completed") options.noActions.push(item.id)
                    })
                }
            }, {immediate: true});
            /** watch end **/

            // SET DATATABLE PROPS
            let options = {
                name: 'Project List',
                columns: [
                    {
                        title: 'Status',
                        type: 'radio',
                        key: 'status',
                        modifier: (value, row) => {
                            return value === 'Active' ? 1 : 0;
                        },
                        modifier2: (value, row) => { //if status not active or inactive then work
                            return value === ('Completed' || 'Canceled') ? true : false
                        },
                        show: data.permission.status
                    },
                    {
                        title: 'Serial',
                        type: 'text',
                        key: 'serial',
                        sort: true
                    },
                    {
                        title: 'Type',
                        type: 'text',
                        key: 'type',
                        sort: true
                    },
                    {
                        title: 'Project',
                        type: 'link',
                        key: 'project_name',
                        sort: true,
                        modifier: (obj, row) => {
                            return row.project_configured != 1 ? `/projects/${row.id}/start-activity` : `/projects/${row.id}/project-dashboard`
                        }
                    },
                    {
                        title: 'Leader',
                        type: 'text',
                        key: 'teamlead'
                    },
                    {
                        title: 'Date',
                        type: 'date',
                        key: 'start_date',
                        format: "MMMM Do YYYY",
                        sort: true
                    },
                ],
                showAction: true,
                action: [
                    {
                        title: 'Edit',
                        icon: 'edit',
                        type: 'edit',
                        show: data.permission.canEdit
                    },
                    {
                        title: 'Delete',
                        icon: 'trash-alt',
                        type: 'delete',
                        show: data.permission.canDelete
                    }
                ],
                noActions: []
            }

            async function changeAction(action, row) {
                if (action.type === 'edit') {
                    await editFormData({url: `${data.setURL}/${row.id}`, modalId: 'showModal'}, false, setFormData)
                }
                if (action.type === 'delete') {
                    deleteListData(`${data.setURL}/${row.id}`, setParams)
                }
            }

            const changeStatus = (column, row) => {
                let input = {}
                if (row.id) {
                    input._method = 'PUT'
                }
                storeFormData({url: `${vueConfig.Project.ChangeStatusEndPoint}/${row.id}`}, input, false, setParams)
            }
            const pagination = (page) => {
                setParams.page = page
                getAllData(data.setURL, setParams)
            }
            const limitChange = (limit) => {
                setParams.limit = limit
                delete setParams.page
                getAllData(data.setURL, setParams);
            }
            const sorting = (column) => {
                setParams.column = column.key
                setParams.sort = setParams.sort == 'asc' ? 'desc' : 'asc'
                getAllData(data.setURL, setParams);
            }

            function formAction(object, inputData) {
                inputData.company_id = company.id == 0 ? null : company.id
                if (inputData.id) {
                    inputData._method = 'PUT'
                    object.url = `${object.url}/${inputData.id}`
                }
                storeFormData(object, inputData, false, setParams)
            }

            function searchList() {
                setParams.status = setParams.status == 'null' ? null : setParams.status
                setParams.type = setParams.type == 'null' ? null : setParams.type
                getAllData(data.setURL, setParams);
            }

            function completedProjects() {
                return store.getters.getCompletedProjects
            }

            function setFormData(formData) {
                data.inputFormData.project_id = formData.project_id ? formData.project_id : ''
            }

            //VALIDATION
            const schema = Yup.object().shape({
                project_name: Yup.string().required(),
                serial: Yup.string().required(),
                start_date: Yup.string().required(),
                status: Yup.string().required()
            });

            return {
                openModal, changeAction, changeStatus, formAction, sorting, setParams,
                pagination, limitChange, data, options, storeFormData, schema, searchList,
                completedProjects, resetForm
            }
        }
    }
</script>

<style scoped>

</style>
